import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import SectorPage from '../../components/sectorPage'

const SpecializedPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/distribution-specialisee" type="sector">
      <SEO 
        title="Classement des meilleurs sites de la distribution spécialisée - 2021"
        description="Quels sont les meilleurs sites de la distribution spécialisée de l'Observatoire de l'impact positif ? Comment se démarquent les sites de distribution spécialisée en ligne ?"
        path="/2021/distribution-specialisee"
        year="2021" />
      
      <Breadcrumb url="/2021/distribution-specialisee" label="Distribution spécialisée" year="2021" />

      <SectorPage slug="specialized" type="sector" year="2021" />
    </Layout>
  )
  }

export default SpecializedPage

