import React, {useState, useEffect}  from 'react'

import ContactSection from './contactSection'
import RankingContent from './rankingContent'

import texts2022 from '../json/texts.json'
import texts2021 from '../json/2021/texts.json'

const SectorPage = ({slug, year = '2022'}) => {
  const texts = year === '2021' ? texts2021 : texts2022
  const [content, setContent] = useState(texts[slug]['global'])

  useEffect(() => {
    const texts = year === '2021' ? texts2021 : texts2022
    setContent( texts[slug]['global'])
  }, [year, slug])

  return (
    <>
      {content &&
        <div className={`page has-border-top page--${slug}`}>
          <div className="fluid-grid">
            <h1 className="sr-only">{content.titleSingle}</h1>
            
            <div className="lg:pt-10 lg:pb-20">
              <RankingContent
                isSinglePage={true}
                currentSector={slug}
                currentCriteria="global"
                year={year} />
            </div>
            
            <div className="subsection">
              <RankingContent
                isSinglePage={true}
                currentSector={slug}
                currentCriteria="usability"
                year={year}
                noText={year === '2022' ? true : false} />
            </div>

            <div className="subsection">
              <RankingContent
                isSinglePage={true}
                currentSector={slug}
                currentCriteria="conception"
                year={year}
                noText={year === '2022' ? true : false} />
            </div>

            <div className="subsection">
              <RankingContent
                isSinglePage={true}
                currentSector={slug}
                currentCriteria="accessibility"
                year={year}
                noText={year === '2022' ? true : false} />
            </div>

            <div className="subsection">
              <RankingContent
                isSinglePage={true}
                currentSector={slug}
                currentCriteria="seo"
                year={year}
                noText={year === '2022' ? true : false} />
            </div>
          </div>
        </div>
      }
      <ContactSection />
    </>
  )
}

export default SectorPage